import React, {
  useCallback,
  useMemo,
  useContext,
  useRef,
  useEffect
} from 'react';
import { tracking } from './tracking';
import { TrackingContext } from './TrackingContext';

const useTrackingContext = (eventData) => {
  const context = useContext(TrackingContext);

  const latestEventData = useRef(eventData);

  useEffect(() => {
    // store the latest data & options in a mutable ref to prevent
    // dependencies from changing when the consumer passes in non-memoized objects
    latestEventData.current = eventData;
  });

  const getContextData = useCallback(() => {
    const ownTrackingData = latestEventData.current || {};
    const contextData = context.getContextData ? context.getContextData() : {};
    return Object.assign({}, contextData, ownTrackingData);
  }, [context]);

  const trackEvent = useCallback(
    (eventPath, data = {}, eventCallback) => {
      const mergedData = Object.assign({}, getContextData(), data);
      tracking.trackEvent(eventPath, mergedData, eventCallback);
    },
    [getContextData]
  );

  return useMemo(
    () => ({
      getContextData,
      trackEvent
    }),
    [getContextData, trackEvent]
  );
};

export const useTracking = (eventData) => {
  const trackingContext = useTrackingContext(eventData);

  const Track = useCallback(
    ({ children }) => {
      return (
        <TrackingContext.Provider value={trackingContext}>
          {children}
        </TrackingContext.Provider>
      );
    },
    [trackingContext]
  );

  return useMemo(
    () => ({
      Track,
      trackEvent: trackingContext.trackEvent,
      getContextData: trackingContext.getContextData
    }),
    [trackingContext, Track]
  );
};
