/* eslint-disable no-unused-expressions */
import amplitude from 'amplitude-js';
import { get, pick, withDefault, toEventName, merge } from './utils';
import { AMPLITUDE_CONFIG } from './constants';

export class TrackingClient {
  get _instance() {
    return this._enabled ? this._client : null;
  }

  /**
   * Initializes the tracking client
   * @param {object} config
   * @param {object} [config.events] - Event taxonomy used to validate event tracking.
   * @param {array} [config.userGroupProperties] - Properties that should be used as user group properties. See docs for more details (https://developers.amplitude.com/docs/javascript#user-groups).
   * @param {boolean} [config.enabled] - Define if tracking is enabled (defaults to false).
   * @param {boolean} [config.debug] - Enable debug logging.
   * @param {object} [config.amplitudeConfig] - AmplitudeClient config. See amplitude docs for more details (https://github.com/amplitude/Amplitude-JavaScript/blob/main/src/options.js).
   * @param {object} config.amplitudeToken - Amplitude client token.
   * @param {function} [config.eventDataHook] - Hook for appending or transforming event data.
   */
  init({
    enabled,
    events,
    userGroupProperties,
    debug,
    amplitudeConfig,
    amplitudeToken,
    eventDataHook
  }) {
    if (this._instance) return;

    this._client = amplitude.getInstance();
    this._userUid = null;
    this._events = withDefault(events, {});
    this._userGroupProperties = withDefault(userGroupProperties, []);
    this._enabled = withDefault(enabled, false);
    this._debug = withDefault(debug, false);
    this._eventDataHook = withDefault(eventDataHook, false);
    this._amplitudeConfig = this._getAmplitudeConfig(amplitudeConfig);

    this._client.init(amplitudeToken, null, this._amplitudeConfig);
  }

  /**
   * Sets an identifier for the current user.
   * @param {string} userUid - identifier to set. Can be null.
   */
  setUserUid(userUid) {
    if (!this._userUid) {
      this._userUid = userUid;
      this._instance?.setUserId(userUid);
    }
  }

  /**
   * Sets user properties for the current user.
   * @param {object} userProperties - object with string keys and values for the user properties to set.
   */
  setUserProperties(userProperties) {
    this._instance?.setUserProperties(userProperties);
  }

  /**
   * Sets the value of a given user property. If a value already exists, it will be overwritten with the new value.
   * @param {string} property - The user property key.
   * @param {number|string|list|boolean|object} value - A value or values to set.
   */
  setUserProperty(property, value) {
    const identify = new amplitude.Identify().set(property, value);
    this._instance?.identify(identify);
  }

  /**
   * Sets the value of a given user property only once. Subsequent setOnce operations on that user property will be ignored;
   * however, that user property can still be modified through any of the other operations.
   * Useful for capturing properties such as 'initial_signup_date', 'initial_referrer', etc.
   * @param {string} property - The user property key.
   * @param {number|string|list|boolean|object} value - A value or values to set.
   */
  setOnceUserProperty(property, value) {
    const identify = new amplitude.Identify().setOnce(property, value);
    this._instance?.identify(identify);
  }

  /**
   * Add user to a group or groups. You need to specify a groupType and groupName(s).
   *
   * For example you can group people by their organization.
   * In that case, groupType is "orgId" and groupName would be the actual ID(s).
   * groupName can be a string or an array of strings to indicate a user in multiple groups.
   * You can also call setGroup multiple times with different groupTypes to track multiple types of groups (up to 5 per app).
   *
   * Note: this will also set groupType: groupName as a user property.
   * See the [advanced topics article](https://developers.amplitude.com/docs/setting-user-groups) for more information.
   * @param {string} groupType - the group type (ex: orgId)
   * @param {string|list} groupName - groupName - the name of the group (ex: 15), or a list of names of the groups
   */
  setGroup(groupType, groupName) {
    this._instance?.setGroup(groupType, groupName);
  }

  /**
   * Unset and remove a user property. This user property will no longer show up in a user's profile.
   * @param {string} property - The user property key.
   */
  unsetUserProperty(property) {
    const identify = new amplitude.Identify().unset(property);
    this._instance?.identify(identify);
  }

  /**
   * Append a value or values to a user property.
   * If the user property does not have a value set yet,
   * it will be initialized to an empty list before the new values are appended.
   * If the user property has an existing value and it is not a list,
   * the existing value will be converted into a list with the new values appended.
   * @param {string} property - The user property key.
   * @param {number|string|list|object} value - A value or values to append.
   */
  appendUserProperty(property, value) {
    const identify = new amplitude.Identify().append(property, value);
    this._instance?.identify(identify);
  }

  /**
   * Prepend a value or values to a user property.
   * Prepend means inserting the value or values at the front of a list.
   * If the user property does not have a value set yet,
   * it will be initialized to an empty list before the new values are prepended.
   * If the user property has an existing value and it is not a list,
   * the existing value will be converted into a list with the new values prepended.
   * @param {string} property - The user property key.
   * @param {number|string|list|object} value - A value or values to prepend.
   */
  prependUserProperty(property, value) {
    const identify = new amplitude.Identify().prepend(property, value);
    this._instance?.identify(identify);
  }

  /**
   * Increment a user property by a given value (can also be negative to decrement).
   * If the user property does not have a value set yet, it will be initialized to 0 before being incremented.
   * @param {string} property - The user property key.
   * @param {number|string} value - The amount by which to increment the user property. Allows numbers as strings (ex: '123').
   */
  addUserProperty(property, value) {
    const identify = new amplitude.Identify().add(property, value);
    this._instance?.identify(identify);
  }

  /**
   * Remove a value or values to a user property, if it does exist in the user property.
   * If the item does not exist in the user property, it will be a no-op.
   * @param {string} property - The user property key.
   * @param {number|string|list|object} value - A value or values to remove.
   */
  removeUserProperty(property, value) {
    const identify = new amplitude.Identify().remove(property, value);
    this._instance?.identify(identify);
  }

  /**
   * This is the callback for trackEvent. It gets called after the event is uploaded,
   * and the server response code and response body from the upload request are passed to the callback function.
   * @callback eventCallback
   * @param {number} responseCode - Server response code for the event upload request.
   * @param {string} responseBody - Server response body for the event upload request.
   */

  /**
   * Log an event with eventName and eventData
   * @param {string} eventName - name of event
   * @param {object} eventData - (optional) an object with string keys and values for the event properties.
   * @param {eventCallback} eventCallback - (optional) a callback function to run after the event is logged.
   */
  trackEvent(eventPath, eventData, eventCallback) {
    const eventConfig = get(this._events, eventPath);
    const eventName = this._getEventName(eventPath);

    if (!this._isValidEvent(eventConfig, eventPath)) {
      eventCallback && eventCallback();
      return;
    }

    const data = this._attachEventMeta(eventPath, eventData);

    const userGroupProperties = pick(data, this._userGroupProperties);

    if (Object.keys(userGroupProperties).length) {
      this._instance?.logEventWithGroups(
        eventName,
        data,
        userGroupProperties,
        eventCallback
      );
    } else {
      this._instance?.logEvent(eventName, data, eventCallback);
    }

    this._debugTrackEvent(eventName, data);
  }

  /**
   * This method allows for setting or updating properties of particular groups.
   * These updates will only affect events going forward.
   * @param {string} groupType - The group type (e.g. 'orgUid')
   * @param {string} groupName - The group name (e.g. uid value)
   * @param {string} property - The group property key.
   * @param {number|string|list|boolean|object} value - A value or values to set.
   */
  groupIdentify(groupType, groupName, property, value) {
    const identify = new amplitude.Identify().set(property, value);
    this._instance?.groupIdentify(groupType, groupName, identify);
  }

  /**
   * This method allows for registering events used to validate event paths and event data.
   * @param {object} events - The events taxonomy to be registered
   */
  registerEvents(events) {
    this.events = merge(this._events, events);
  }

  _attachEventMeta(eventPath, eventData) {
    const [product, source, action] = eventPath.split('.');
    const eventMeta = { product, source, action };
    const mergedData = Object.assign({}, eventData, eventMeta);

    if (this._eventDataHook !== false) {
      return this._eventDataHook(mergedData);
    } else {
      return mergedData;
    }
  }

  _getEventName(eventPath) {
    return get(this._events, eventPath) ? toEventName(eventPath) : undefined;
  }

  _isValidEvent(eventConfig, eventPath) {
    const isDefined = typeof eventConfig !== 'undefined';

    if (!isDefined) {
      this._log('error', `Invalid eventPath: ${eventPath}`);
    }

    const isEnabled = eventConfig === true;

    return isEnabled && isDefined;
  }

  _isValidEventProps(eventName, eventPath) {
    const isValid = typeof eventName !== 'undefined';

    if (!isValid) {
      this._log('error', `Invalid eventPath: ${eventPath}`);
    }

    return isValid;
  }

  _log(level, message) {
    if (this._debug) {
      console[level](`[TrackingClient] ${message}`);
    }
  }

  _debugTrackEvent(eventName, eventData) {
    if (this._debug) {
      const eventDataString = JSON.stringify(eventData || {});
      this._log('info', `Track event: ${eventName} ${eventDataString}`);
    }
  }

  _getAmplitudeConfig(amplitudeConfig) {
    const config = withDefault(amplitudeConfig, {});
    return Object.assign({}, AMPLITUDE_CONFIG, config);
  }
}
