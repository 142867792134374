export function get(obj, path) {
  if (!obj) return undefined;

  const fullPath = path
    .replace(/\[/g, '.')
    .replace(/]/g, '')
    .split('.')
    .filter(Boolean);

  const exists = fullPath.every((step) => {
    return !(step && (obj = obj[step]) === undefined);
  });

  return exists ? obj : undefined;
}

export const withDefault = (value, defaultValue) =>
  typeof value !== 'undefined' ? value : defaultValue;

export const toEventName = (eventPath) =>
  eventPath
    .replace(/([A-Z])/g, ' $1')
    .replace(/[.]/g, ' ')
    .toLowerCase();

const isObject = (obj) => obj && typeof obj === 'object';

export function merge(target, source) {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = sourceValue;
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = merge(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export function pick(obj, keys) {
  if (!isObject(obj) || !Array.isArray(keys)) {
    return {};
  }

  return Object.keys(obj)
    .filter((i) => keys.includes(i))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}
