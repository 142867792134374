import { useExperiment } from './useExperiment';

/**
 * Hook for getting a flag variant
 *
 * (Proxy method for useExperiment)
 *
 * @param {string} flagName - The flag name to fetch
 */
export const useFlag = useExperiment;
